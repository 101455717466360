import React from "react"
import Utils from "../utils/node.utils"

import Layout from "../components/Layout/Layout/Layout"
import SEO from "../components/SEO/SEO"
import { StaticQuery, graphql } from "gatsby"

const NotFoundPage = ({ location }) => {
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )

        return (
          <Layout
            origin={location.origin}
            originPage="404"
            commonContentMap={commonContentMap}
          >
            <SEO
              title="404: Not found"
              keywords={[`lotta mae`, `not found page`]}
            />
            <div className="container cart-link">
              <div className="mb-4 text-center text-uppercase product-text-resize">
                Page not found
              </div>
              <div className="mb-6 text-center">
                We're sorry, the page you requested could not be found.
              </div>
            </div>
          </Layout>
        )
      }}
    />
  )
}

export default NotFoundPage

const pageQuery = graphql`
  {
    commonContent: commonContentEngb {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`
